/*Grid border-radius*/
.blocks-group-wrapper.grey {
  & {
    border-radius:0 10vw 0 10vw;
  }
}
.blocks-group-wrapper.grey > div{
  background-color:transparent !important;
}

/*Grid border-radius*/
.blocks-group-wrapper.light-blue {
  background-color: var(--primary-30-color);
  & {
    border-radius: 0 10vw 0 10vw;
  }
}

.blocks-group-wrapper.light-blue>div {
  background-color: transparent !important;
}

/*Grid border-radius*/
.blocks-group-wrapper.primary {
  background-color: var(--primary-color);

  & {
    border-radius: 0 10vw 0 10vw;
  }
}

.blocks-group-wrapper.primary>div {
  background-color: transparent !important;
}