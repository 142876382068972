.contenttype-resource {
  .documentFirstHeading {
    margin-top: 0px;
  }
  .blocks-group-wrapper {
    padding-top: 0px;
  }
}
@media (max-width: $largest-mobile-screen) {
  .contenttype-news-item #page-document .dates .day {
    display: block;
    margin-top:2rem;
    text-align:left;
  }
  .contenttype-news-item h1.documentFirstHeading,
  .contenttype-resource h1.documentFirstHeading{
    font-size:2rem;
    margin:1rem !important;
    line-height:2.3rem;
    text-align:left !important;
  }
}

.block.separator .line::after {
  border-top: 5px solid var(--back-grey);
}

.block.separator {
  margin-left: auto !important;
  margin-right: auto !important;
}
@media only screen and (max-width: $largest-mobile-screen) {
  #page-document .block.separator {
    margin-top: $spacing-small;
    margin-bottom: $spacing-small;
  }
}

.styled-dividerBlock{
  clear:both;
}
.ui.divider:not(.vertical):not(.horizontal) {
  border-top: 5px solid var(--back-grey);
  border-bottom: none;
}
.ui.divider.divider-spacing-l {
  margin-block: var(--spacing-block-lg, 4rem);
}
.ui.divider.divider-spacing-m {
  margin-block: var(--spacing-block-lg, 2rem);
}
.ui.divider.divider-spacing-s {
  margin-block: var(--spacing-block-lg, 1rem);
}



@media only screen and (max-width: $largest-mobile-screen) {
  #page-document .block.introduction {
    margin-top: $spacing-small;
    margin-bottom: $spacing-small;
  }
  .block.image.align.left,
  .block.image.align.right {
    margin-top: $spacing-small;
    margin-bottom: $spacing-small !important;
  }
  .block.image figure.left,
  .block.image figure.right{
    margin-bottom: $spacing-small !important;
  }
}
/**/

.card-container {
  width: 100%;
  height: 100% !important;
  background-color: white !important;
  border: 1px solid var(--back-grey);
  text-align: center;
  margin-bottom:1rem;
  background-color: white;
  border-radius: 0 0 6px 6px;
  height: 100%;
  a {
    color: black !important;
  }
  a:hover{
    color:var(--primary-color) !important;
  }

  h3 {
    font-size: 1.15rem;
    font-weight: 500;
  }

  h4 {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.3rem;
    padding: 0 1rem;
    margin-top: 1rem;

  }

  .resource-type {
    text-transform: uppercase;
    font-weight: 200;
    font-size: 0.9rem;
  }
  .date{
    font-size:0.9rem;
  }
}
@media only screen and (max-width: $largest-mobile-screen) {
  #page-document .blocks-group-wrapper>* {
    max-width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
}
/*images crop*/
.card-container img{
  object-fit: cover;
  object-position: center;
  width: auto;
  min-width: 100%;
  max-height: 210px;
  min-height: 210px;
}
.featured-element .card-container img {
  max-height:100%;
}

/**/

.accordion-block .ui.styled.accordion .title.accordion-title>span,
.accordion-block .ui.styled.accordion .title.accordion-title .input-accordion-title,
.block-editor-accordion .ui.styled.accordion .title.accordion-title>span,
.block-editor-accordion .ui.styled.accordion .title.accordion-title .input-accordion-title {
  font-size: 1rem;
  font-family: var(--custom-main-font, "Montserrat", sans-serif);
  color:black;
  font-weight:500;
}
#page-add .block-editor-gridBlock .block.gridBlock .slate:not(.inner),
#page-edit .block-editor-gridBlock .block.gridBlock .slate:not(.inner),
.block.gridBlock .slate:not(.inner) {
  padding: 1rem;
  margin: 0;
  background-color: white;
}

/***modeloa***/
:root {
  // --layout-container-width: #{$layout-container-width};
  // --default-container-width: #{$default-container-width};
  // --narrow-container-width: #{$default-container-width};
}

@media only screen and (min-width: 768px) {
  .blocks-group-wrapper {
    padding-top: 0rem;
    padding-bottom: 0rem;
  }
}

.block.video.center,
.block.video.full,
.block.video.wide {
  margin-top: 0;
  margin-bottom: 0;
}

#page-document .blocks-group-wrapper.grey {
  max-width: var(--layout-container-width);
  padding:2rem 1rem 1rem 1em;
  margin:2rem auto;
}
@media only screen and (min-width: 768px) {
  #page-document .blocks-group-wrapper.grey {
    padding: 4rem;
  }
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child {
  margin-bottom: inherit;
}


#page-document .columns-view blockquote,
#page-document .columns-view h2,
#page-document .columns-view h3,
#page-document .columns-view h4,
#page-document .columns-view h5,
#page-document .columns-view h6,
#page-document .columns-view li,
#page-document .columns-view ol,
#page-document .columns-view p,
#page-document .columns-view pre,
#page-document .columns-view span,
#page-document .columns-view ul{
  max-width:100%;
  margin:0;
}
.block.image.align.left,
.block.image.align.right,
.block.image figure.center {
  margin-top: 0px;
  margin-bottom:1rem;
}
.blocks-group-wrapper .blocks-group-wrapper{
  padding-bottom:0;
}
#page-document .blocks-group-wrapper:last-child {
  margin-bottom: 0rem !important;
}
#page-document .blocks-group-wrapper.grey,
#page-document .blocks-group-wrapper.primary,
#page-document .blocks-group-wrapper.light-blue{
  h2,h3,h4,h5,h6,ul,ol,li,p,span, blockquote,.block.image.align.left.medium,
  .block.image.align.left.small,
  .block.image.align.right.medium,
  .block.video.align.left,
  .block.video.align.right,
  .block.maps.align.left,
  .block.maps.align.right,
  .block.image.align.right.small,
  .block.video.align.center .video-inner,
  .block.maps.align.center .maps-inner,
  .block.image.align.center figure,
  .event-details,
  .download-event,
  .blocks-group-wrapper>pre,
  .blocks-group-wrapper>.block.code {
    max-width: 100%;
    margin: 0;
  }
}

/*contact form*/
.block.form .public-ui .ui.segment{
  border:5px solid var(--primary-10-color);
  border-radius:2rem;
  box-shadow: none;
  h2, p.description{
    display:none;
  }
  button.ui.primary.button{
    background-color: var(--primary-color);
    color:white;
    border-radius:10px;
    padding:1rem 2rem;
    text-decoration: uppercase;
  }
}

/****/
.pdf-link{
  display:inline-block;
}
.pdf-link svg{
  float:left;
  max-height:30px;
}

/**/
.section-search #content-core .tileItem {
  margin-bottom: 1.5em;
  padding: 0 1rem;
  border-radius: 1rem;
  background-color: var(--primary-10-color);
  h2{
    margin-bottom:0 !important;
    a{
      font-weight:600;
    }
  }
  .tileFooter a {
    font-size: 0.8rem;
  }
}