.block.listing .items+.pagination-wrapper,
.column>.block.listing .items+.pagination-wrapper,
.block.search .items+.pagination-wrapper,
.block-editor-search .items+.pagination-wrapper {
    margin-top: 3rem;
}

.block.listing .pagination-wrapper,
.column>.block.listing .pagination-wrapper,
.block.search .pagination-wrapper,
.block-editor-search .pagination-wrapper {
    display: table;
    margin: auto;
    margin-top: auto;
    border-collapse: collapse;
    font-size: 0.9rem;
    line-height: 1.2rem;
    font-weight: 300;
    a{
        font-size: 0.9rem;
        color:var(--primary-color) !important;
    }
}

.pagination-wrapper {
    text-align: center;
}
.block.listing .pagination-wrapper .ui.pagination.menu .item.active,
.column>.block.listing .pagination-wrapper .ui.pagination.menu .item.active,
.block.search .pagination-wrapper .ui.pagination.menu .item.active,
.block-editor-search .pagination-wrapper .ui.pagination.menu .item.active {
    background-color: var(--primary-color);
    color:white !important;
}
.block.listing .pagination-wrapper .ui.pagination.menu .item,
.column>.block.listing .pagination-wrapper .ui.pagination.menu .item,
.block.search .pagination-wrapper .ui.pagination.menu .item,
.block-editor-search .pagination-wrapper .ui.pagination.menu .item {
    border: solid 1px var(--primary-30-color);
}
.pagination-wrapper{
}
.pagination-wrapper a.disabled{
    display:none !important;
}
.pagination-wrapper a{
    margin:0.5rem;
    display:inline-block !important;
}
.pagination-wrapper .total{
    display:none !important;
}