
.header-wrapper {
  /*Header fixed when scrolling*/
  background-color: white;
  width: 100%;

  /*Logo change animation*/
  .logo_s {
    opacity: 1;
    visibility: visible;
    @media only screen and (min-width: $largest-mobile-screen) {
      visibility: hidden;
      opacity: 0;
      transition: all 3s;
      height: 0;
    }
  }
  .logo_xl {
    visibility: hidden;
    opacity: 0;
          height: 0;
    @media only screen and (min-width: $largest-mobile-screen) {
      opacity: 1;
      visibility: visible;
      transition: all 3s;
      height: max-content;
    }
  }
  &.small-header {
    @media only screen and (min-width: $largest-mobile-screen) {
      .logo_s {
          visibility: visible;
          opacity: 1;
          height: max-content;
        }

        .logo_xl {
          visibility: hidden;
          opacity: 0;
          height: 0;
        }
    }

  }
  border-bottom: 1px solid var(--primary-30-color);
  .header .logo-nav-wrapper {
    padding-top: 0;
    padding-bottom: 0;

    // align-items: end;
    // justify-content: end;
    .logo {
      flex: none;
      margin-right: auto;
      @media only screen and (max-width: $largest-mobile-screen) {
        flex: 1 1 0;
      }
    }

    .navigation {
      flex: none;
      align-self: end;
      padding-right: 2rem;
      // height:auto;
      ul.desktop-menu .submenu-wrapper {
        margin-top: 16px;

        .submenu {
          background-color: var(--back-grey);
          padding: 0 4vw 2vw 4vw;

          .close {
            background-color: transparent;
            top: 1vw;
            right: 4vw;
            margin-left: 0;
          }

          a {
            padding: 0.5rem 0;
            font-size: 0.9rem;
            line-height: 1.3rem;
            font-weight: 600;
          }
        }

        .subitem-wrapper {
          display: flex;
          align-items: center;
        }
      }

      .item {

        &.active::before,
        &:hover::before {
          bottom: -25px;
          border-bottom: 9px solid var(--primary-color);
        }
      }

      .item>span {

        &.active::before,
        &:hover::before {
          position: absolute;
          bottom: -25px;
          width: 100%;
          border-bottom: 9px solid var(--primary-color);
          content: '';
        }
      }

      .item.active.current>span {

        &::before,
        &:hover::before {
          position: absolute;
          bottom: -25px;
          width: 100%;
          border-bottom: 9px solid var(--primary-color);
          content: '';
        }
      }
    }

    .search-wrapper {
      flex: none;

      .search button svg {
        height: 36px;
      }

      .search button:hover {
        background-color: var(--primary-color);
      }

      .search-bar {
        background-color: var(--back-grey);

        .ui.container form .searchbox input {
          background-color: var(--primary-10-color);
        }

        .ui.container form .searchbox button:hover {
          background-color: var(--primary-color);
        }
      }
    }
  }
}
body.is-anonymous .header-wrapper {
  @media only screen and (min-width: $largest-mobile-screen) {
      position: fixed;
      z-index: 11;
    }
}
/*mainmenu*/
#navigation{
  padding-right:0;
}
.desktop-menu li{
  margin-right:2rem;
}

.header-wrapper .header .logo-nav-wrapper .navigation .item>span.active::before,
.header-wrapper .header .logo-nav-wrapper .navigation .item>span:hover::before,
.header-wrapper .header .logo-nav-wrapper .navigation .item.active.current>span::before,
.header-wrapper .header .logo-nav-wrapper .navigation .item.active.current>span:hover::before,
.header-wrapper .header .logo-nav-wrapper .navigation .item:hover::before,
.header-wrapper .header .logo-nav-wrapper .navigation .item.active::before,
.header-wrapper .header .logo-nav-wrapper .navigation .item:hover::before,
.header-wrapper .header .logo-nav-wrapper .navigation .item.active::before {
    bottom: -15px;
    border-bottom: 5px solid var(--primary-color);
}

.navigation ul.desktop-menu>li>button::after{
  content:"";
  background: url("./images/i_down.svg") no-repeat center center;
  height:20px;
  width:20px;
  display:inline-block;
  float:right;
  margin-left:0.5rem;
}
.header-wrapper .header .logo-nav-wrapper .navigation ul.desktop-menu .submenu-wrapper {
  margin-top: 5px;
}
.navigation .item {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.navigation ul.desktop-menu .submenu-inner .subitem-wrapper:nth-of-type(n+6) {
  margin-top: 1rem;
}

/*Active and hover for links*/

/*LanguageSelector*/
.language-selector{
  margin-right:0;
}
.language-selector a {
  color: $black;
  font-size:0.8rem;

  &:hover {
    color:var(--primary-color)
  }
  &.selected{
    font-weight:600;
  }
}
.language-selector a:not(:last-child)::after {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
/*DrawerMenu*/

.mobile-nav div.menu-drawer {
  display: flex;
  flex-direction: column;
    height: calc(100vh - 75px);
  .search-header {
    justify-content: space-between;
  }
}

ul.mobile-tools {
  align-self: center;
}

.menu-drawer ul.mobile-tools li a {
  color: $black;
  text-transform: uppercase;
  text-decoration: none;
}


@media only screen and (max-width: $largest-mobile-screen) {
    .menu-drawer {
        top: 76px !important;
      }
  .tools-wrapper .language-selector {
    display: none;
  }
}


/***/
.header-wrapper .header {
  max-width: 94vw;
}
.has-toolbar .header-wrapper .header {
  max-width:90vw;
}
.menu-and-search{
  display:flex;
}
.small-header .header-right {
  display:flex;
  flex-direction: row-reverse;
  margin-top:4px;
}
/*search*/
.header-wrapper .header .logo-nav-wrapper .search-wrapper .search-bar .ui.container form .searchbox input {
  background-color: white;
}
.header-wrapper .header .search-wrapper .search .search-bar .ui.container form .searchbox input {
  font-size: 1.2rem;
}
.search-button svg{
  height:40px !important;
}
.header-wrapper .header .search-wrapper .search button {
  width: 55px;
  height: 55px;
}
/*hau behar bda ez da behar!!! bilaketa orri propio bat egiten badugu adbz...*/
.header-wrapper .header .logo-nav-wrapper .search-wrapper .search-bar .ui.container form .searchbox input {
  background-color: rgba(255, 255, 255, 0.3);
  padding: 5px;
}
/**/