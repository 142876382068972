// Grid variation
// .block.listing.grid_three_columns {
//   &.next--has--same--backgroundColor.next--is--same--block-type,
//   &.next--is--__button {
//     .listing-item:last-child {
//       padding-bottom: 0 !important;
//       border-bottom: none !important;
//     }
//   }

//   .items {
//     display: flex;
//     flex-wrap: wrap;
//     @media only screen and (max-width: $largest-mobile-screen) {
//       flex-direction: column;

//       .listing-item {
//         padding-bottom: 20px !important;
//       }
//     }
//   }
//   .headline {
//     @include block-title();
//     margin-right: 0 !important;
//     margin-left: 0 !important;
//   }
//   .listing-item {
//     align-items: normal;
//     border-bottom: none;
//     margin: 0 !important;

//     // @media only screen and (min-width: $tablet-breakpoint) {
//     //   width: 33%;
//     //   padding-top: 10px;
//     //   padding-bottom: 10px !important;

//     //   &:nth-child(2n) {
//     //     padding-left: 10px !important;
//     //   }

//     //   &:nth-child(2n + 1) {
//     //     padding-right: 10px !important;
//     //   }

//     //   &:last-child,
//     //   &:nth-last-child(2):not(:nth-child(2n)) {
//     //     padding-bottom: 0 !important;
//     //   }

//     //   &:first-child,
//     //   &:nth-child(2) {
//     //     padding-top: 0 !important;
//     //   }
//     // }

//     // &:last-child:nth-child(2n + 1) {
//     //   @media only screen and (min-width: $largest-mobile-screen) {
//     //     margin-left: 0 !important;
//     //   }
//     // }

//     // .card-container {
//     //   width: 100%;
//     //   height: 100% !important;
//     //   background-color: $lightgrey;
//     // }

//     img.item-image {
//       width: 100%;
//       margin: 0;
//       aspect-ratio: $aspect-ratio !important;
//     }

//     .item {
//       margin-top: 40px;
//     }

//     .content {
//       padding: 0 20px 40px 20px;

//       .headline {
//         padding: 0 !important;
//         margin-bottom: 20px;
//         color: $black;
//         letter-spacing: 1px;
//         text-transform: uppercase;
//         @include headtitle1();
//         @include word-break();
//       }

//       h2 {
//         margin: 0 0 20px 0;
//         color: $black;
//         @include text-heading-h3();
//       }
//       p {
//         margin-bottom: 0;
//         @include body-text();
//       }
//       p:empty {
//         display: none;
//       }
//     }
//   }
// }

.block.listing.grid_three_columns,
.block.listing.grid_four_columns,
.block.search.grid_three_columns,
.block.search.grid_four_columns {
  &.next--has--same--backgroundColor.next--is--same--block-type,
  &.next--is--__button {
    .listing-item:last-child {
      padding-bottom: 0 !important;
      border-bottom: none !important;
    }
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    @media only screen and (max-width: $largest-mobile-screen) {
      flex-direction: column;

      .listing-item {
        padding-bottom: 20px !important;
      }
    }
  }
  .listing-item {
    align-items: normal;
    border-bottom: none;
    margin: 0 !important;
    img.item-image {
      width: 100%;
      margin: 0;
      aspect-ratio: $aspect-ratio !important;
    }
    a {
      color: black;
    }
    a:hover h4 {
      color: var(--primary-color) !important;
    }
    // a:hover img{
    //   transform: scale(0.9);
    //   transition: 50ms;
    // }
    .content {
      padding: 0 1rem 2rem 1rem;
      h4 {
        padding: 0;
      }
    }
  }
}

/* Circle effect */
.listing-item .item-news {
  position: relative;
  overflow: hidden;
}

.listing-item .item-news::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: '';
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}
.listing-item .item-news:hover {
  border-color: var(--primary-30-color);
}
.listing-item .item-news:hover::before {
  -webkit-animation: circle 0.75s;
  animation: circle 0.75s;
}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}

@keyframes circle {
  0% {
    opacity: 1;
  }

  40% {
    opacity: 1;
  }

  100% {
    width: 200%;
    height: 150%;
    opacity: 0;
  }
}
/***/

// Grid variation
.block.listing.grid_three_columns {
  .listing-item {
    @media only screen and (min-width: $tablet-breakpoint) {
      width: 33%;
      padding-top: 0;
      padding-bottom: 2rem !important;
      padding-left: 1rem !important;

      &:nth-child(3n + 1) {
        clear: both;
        padding-left: 0 !important;
      }
    }
  }
}

.block.listing.grid_four_columns {
  .listing-item {
    @media only screen and (min-width: $tablet-breakpoint) {
      width: 25%;
      padding-top: 0;
      padding-bottom: 2rem !important;
      padding-left: 2rem !important;

      &:nth-child(4n + 1) {
        clear: both;
        padding-left: 0 !important;
      }
    }
  }
}


/*faceted*/
.ui.searchBlock-facets.grid > .row .three.wide.column{
  background-color: var(--primary-color) !important;
  color:white;
  border-radius: 7vw 0 7vw 7vw;
  @media (min-width: $tablet-breakpoint){
    width: calc(25% - 1rem) !important;
    margin-right: 1rem;
    min-height: 900px;
  }

}

#page-document .searchBlock-facets .three.wide.column .search-wrapper{
  margin: 4rem 2rem;
  width: 80% !important;
}
#page-document .block.search .search-input,
#page-add .block.search .search-input,
#page-edit .block.search .search-input {
  position: relative;
  height: 50px;
    background-color: rgba(255, 255, 255, 0.2);
}
#page-document .block.search .search-input .ui.input,
#page-add .block.search .search-input .ui.input,
#page-edit .block.search .search-input .ui.input {
  height: 50px;
  background-color: rgba(255, 255, 255, 0.3);

}
#page-document .block.search .search-input .ui.input input,
#page-add .block.search .search-input .ui.input input,
#page-edit .block.search .search-input .ui.input input {
  font-size: 20px;
}
#page-document .block.search .search-input .search-input-live-icon-button,
#page-add .block.search .search-input .search-input-live-icon-button,
#page-edit .block.search .search-input .search-input-live-icon-button {
  background-color: transparent !important;
    top: 5px;
}
.searchBlock-facets .three.wide.column .search-wrapper input{
  background-color:rgba(255,255,255,0.3);
  border:1px solid rgba(255,255,255,0.8);
  height:50px;
  font-size:1rem;
  color:white !important;
}
.searchBlock-facets .three.wide.column .search-wrapper input::placeholder{
  color:white !important;
}

.searchBlock-facets .search-input-live-icon-button svg,
#page-document .block.search .search-input .search-input-live-icon-button svg{
  fill:white !important;
  color:white !important;
}
.searchBlock-facets .facets{
  margin-left:2rem;
  margin-right:2rem;
}
.searchBlock-facets .facets h4,
.searchBlock-facets .facets label{
  color:white;
}
@media(min-width:$tablet-breakpoint){
.searchBlock-facets .listing-item {
    width: 30%;
  }
}

.block.search.grid_four_columns .listing-item{
  margin:1rem !important;
}
.entries .entry{
  margin-bottom:0.5rem;
}

.entries .entry label{
  font-weight:400;
  font-size:medium;
}
.facets h4.ui.header{
  border-bottom:1px dotted white;
  padding-bottom:0.5rem;
}
.block.search .ui.accordion.filter-listing .filter-list-group {
  margin: 0.5rem;
}
#page-document .block.search .listing-item:last-child,
#page-add .block.search .listing-item:last-child,
#page-edit .block.search .listing-item:last-child {
  border-bottom: none !important;
}
.ui.basic.buttons .button,
.ui.basic.button {
  color: rgba(0, 0, 0, 1) !important;
}
.block.search .search-results-count-sort,
.block.search .search-results-count-sort h2 {
  margin-bottom: 0 !important;
}
.emptyListing{
  text-align:center;
}
#page-document .block.search .facets,
#page-add .block.search .facets,
#page-edit .block.search .facets {
  padding-bottom: 30px;
}


/**/
.block.listing.default .listing-item:last-child {
  padding-bottom: 1rem !important;
  border-bottom: 1px solid black !important;
}
.block.listing.default{
    .listing-item{
      padding:1em !important;
    }
  .listing-item:hover{
    // background-color: var(--primary-10-color);
    border-color:var(--primary-30-color) !important;
          h2 {
              color: var(--primary-color) !important;
            }
  }
}

#page-document .blocks-group-wrapper .block.listing .listing-item{
  max-width: var(--default-container-width);
}