:root {
  // --custom-main-font: 'Montserrat';
  --primary-color: #4e91cd;
  --primary-60-color: #4e91cd99;
  --primary-30-color: #4e91cd4d;
  --primary-10-color: #4e91cd1a;
  --primary-05-color: #4e91cd0d;
  --primary-link-color: #0861b0;
  --back-grey: #f0f0f0;
}

// $spacing-small;
// $spacing-medium;

@import 'fonts.scss';
@import 'header.scss';
@import 'footer.scss';
@import 'utils.scss';
@import 'home.scss';
@import 'content.scss';
@import 'listing.scss';
@import 'breadcrumb.scss';
@import 'pagination.scss';
@import 'image.scss';

img{
  max-width:100%;
  height:auto;
}
body, h1, h2, h3, h4, h5, h6, p, a, li {
  font-family: var(--custom-main-font, "Montserrat", sans-serif);
}
body,
p,
a,
li {
  font-style: normal;
  font-size: 1rem;
  line-height: 1.6rem;
  font-weight: 300;
}
/***button**/
.ui.button {
  border: 1px solid #000;
  background-color: white;
  font-size:0.9rem;
  font-weight:500;
  color:black;
  border-radius:0;
    &:hover {
        background-color: #000;
        color: #fff;
      }
}
img{
  max-width:100%;
  height:auto;
}
h2, h3 {
  margin-bottom: 1.5rem !important;
}
// cd s
#page-document .blocks-group-wrapper>h1.documentFirstHeading{
  text-align:center;
    margin-top:1.5rem;
  margin-bottom:3rem;
  font-weight:600;
}

.documentDescription{
  color:black;
  font-weight:500;
}
/*news*/
.contenttype-news-item .dates,
.contenttype-resource .dates{
  margin-top:0 !important;
  margin-bottom: 0 !important;
  text-align:center;
}
.contenttype-news-item #page-document .dates .day,
.contenttype-resource #page-document .dates .day {
      font-weight: 300;
}
#page-document .blocks-group-wrapper>h1.documentFirstHeading {
  margin-top: 1rem;
  margin-bottom:2rem;
}
@media only screen and (max-width: $largest-mobile-screen) {
  #page-document .blocks-group-wrapper>h1.documentFirstHeading {
      margin-top: 2rem;
    }
}
.blocks-group-wrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media only screen and (min-width: $largest-mobile-screen) {
      padding-top: 2rem;
      padding-bottom: 2rem;
  }
}
.navigation ul.desktop-menu .submenu-inner .subitem-wrapper a.current{
  color:var(--primary-color);
}
.navigation ul.desktop-menu .submenu-inner .subitem-wrapper a.current .left-arrow {
  visibility: hidden;
}

.block.separator:not(.inner):not([role="presentation"]) {
  padding: 0 !important;
}