/*Breadcrumb*/
#main .breadcrumbs {
    padding: 20px 0;
    background-color: $white;

    .breadcrumb {
        @include layout-container-width();
        @include adjustMarginsToContainer($layout-container-width);
    }
    a{
        text-decoration: none !important;
    }
    a:nth-child(3){
        pointer-events: none;
        cursor: default;
        color:black !important;
    }
}
body.is-anonymous #main .breadcrumbs {
    @media only screen and (min-width: $largest-mobile-screen) {
        margin-top: 105px;
    }
}
#main .breadcrumbs .breadcrumb {
    margin-left:0;
    .section.active {
        font-weight: 400;
    }
    .divider {
        transform: none;
    }

    .divider::before {
        content: ">";
        font-size: 0.8rem;
        margin-right: 0.5rem;
    }
}
@media only screen and (max-width: $largest-mobile-screen) {
    .breadcrumbs{
        display:none;
    }
}