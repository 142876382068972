.contenttype-lrf {
  .gridBlock.four {
    h2 {
      text-align: center;
    }
  }
  .countup-block-wrapper.ui.segment {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:2.2rem 1rem;
    justify-content: center;
    border: 1rem solid var(--primary-10-color);
    box-shadow: none;
    h3 {
      font-size: 1.2rem;
      line-height: normal;
      text-align: center;
      font-weight:300;
      min-height:50px;
    }
    span {
      font-size: 2rem;
      font-weight:700;
      color:var(--primary-color);
    }
  }
}
body.is-amonymous .contenttype-lrf {
  @media only screen and (min-width: $largest-mobile-screen) {
    .ui.basic.segment.content-area {
      margin-top: 3rem;
    }
  }
}
/*slider*/
#page-document .blocks-group-wrapper> .slider {
  max-width: 97vw;
  margin-right: 0;
  margin-left: 3vw;
}
.header-wrapper .header {
  max-width: 94vw;
}

.has-toolbar #page-document .blocks-group-wrapper>.slider {
  max-width: 95vw;
    margin-left: 5vw;
}
.slider .highlight-image-wrapper.gradient img{
  border-radius:0 0 0 15vw;
    height: 60vh;
}
.slider-wrapper{
  height:60vh;
}
.block.slider .teaser-item-title {
  position: absolute;
  top: auto !important;
  bottom: 3rem !important;
  width: 80%;
  left:10%;
  min-height: 10px;
  padding: 0px;
  background: transparent;
  color: #fff;
  text-align:center;
  h2{
    margin-bottom:1rem;
  }
}
@media only screen and (max-width: $tablet-breakpoint) {
  .block.slider .teaser-item-title {
      position: absolute;
      top: 60% !important;
      bottom:0 !important;
  }
}
.slider-button{
  display:none;
}

.slider-dot::after {
  background: var(--primary-30-color);

}
.slider-dot--selected::after {
  background: var(--primary-color);
}
.block.slider .slider-dots {
  padding-bottom: 1rem;
  margin-top: 0.5rem;
}
.block.slider .teaser-item h2{
  margin-bottom: 0 !important;
}
.block.slider .teaser-item p,
.slider-slide .supertitle{
  display:none !important;
}
/*Featured Resources*/
.featured-resources-wrapper {
  background-color: var(--back-grey);
  border-radius:10vw;
  padding: 5vw;
  text-align: center;

  .content{
    padding-bottom:1rem;
  }
  .more-links-buttons {
    padding-top: 5vw;
    a{
      margin:0 0.5rem 1rem 0.5rem;
      display:inline-block;
    }
  }
}
@media only screen and (max-width: $largest-mobile-screen) {
  .featured-resources-wrapper {
      border-radius: 0 10vw 10vw 10vw;
      h2{
        margin-top:2rem;
      }
  }
}
@media only screen and (min-width: $tablet-breakpoint) {
.featured-resources {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: auto auto;
  grid-template-areas:
    'featured featured 1 1'
    'featured featured 1 1';

  &.with-featured {
    .featured-element {
      grid-area: featured;
    }
  }

  &.without-featured {
    grid-template-areas:
      '1 1 1 1'
      '1 1 1 1';
  }

  column-gap: 1.5rem;
  row-gap: 2rem;
    // grid-column-gap: unset;

  .resource {
    height:100%;
    img {

      margin-bottom:1rem;
    }
    a {
      color: black !important;
    }
  }
}
}

/**/
#page-document .blocks-group-wrapper.grey{
  max-width: var(--layout-container-width);
  margin-right: auto;
  margin-left: auto;
}
#page-document .blocks-group-wrapper.light-blue {
  max-width: var(--layout-container-width);
  margin-right: auto;
  margin-left: auto;
}