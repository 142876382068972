/*Footer*/
#footer{
    margin-top: 2rem !important;
    @media only screen and (min-width: $largest-mobile-screen) {
          margin-top: 4rem !important;
    }
}
#footer .footer {
  background-color: white;
  border-top: 2px solid var(--primary-30-color);
  border-top-right-radius: 10vw;
  text-align: left;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding:4vw 4vw 0 4vw;

  ul li{
    border-right:none;
  }
  img.partners-fp {
    margin:0 1rem;
  }
  .logo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin:0;
    ul{
      margin:4rem 0 1rem 0;
      text-transform: uppercase;
      li{
        border-right:none;
        border-left:1px solid #00000080;
        padding-left:0.8rem;
        margin-left:1rem;
      }
      li:first-child{
        margin-left:0;
      }
      a{
        color:black;
        font-size: 0.7rem;
      }
      a:hover{
        text-decoration: underline;
      }
    }
  }
  .contact-info ul {
    display: list-item;
    text-align: left;
    margin-top:0;
    border-left: 4px solid var(--primary-10-color);
    border-right: 4px solid var(--primary-10-color);
    padding-left:2rem;
    a{
      color:black;
      font-size: 0.9rem;
    }
    li{
      padding:0.2rem;font-size: 0.9rem;
    }
    li.address{
      background:url(./images/i_address.svg) no-repeat 0 10px;
      padding-left:2rem;
    }
    li.telephone {
      background: url(./images/i_phone.svg) no-repeat left center;
      padding-left: 2rem;

    }
    li.mail-contact {
      background: url(./images/i_mail.svg) no-repeat left center;
      padding-left: 2rem;
      a{
        text-decoration: underline;
      }
    }
  }

}
@media only screen and (max-width: $largest-mobile-screen) {
  .contact-info ul {
    border-right: none !important;
  }
}

@media (min-width:990px){
    .footer-flex-element {
        width: 33%;
    }
}
@media only screen and (max-width: $tablet-breakpoint) {
  .footer-flex-element{
    padding:1rem 0;
  }
    #footer .footer{
      padding-bottom:3rem;
    }
  #footer .footer .logo ul{
    position: absolute;
    bottom:0;
  }
}
