.block.image {
    figure {
        &.center {

            &.large {
                width: 100% !important;
            }

            &.medium {
                width: 50% !important;
            }

            &.small {
                width: 25% !important;
        }

        &.large {
            figcaption {
                text-align: start;
            }
        }

        &.medium {
            figcaption {
                width: 100%;
                margin-right: auto;
                margin-left: auto;
                text-align: start;
            }
        }
        }
    }
}